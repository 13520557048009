import React, { useEffect } from 'react';
import { getJwt } from '@src/utils/mmc-api/auth';

const Callback = () => {
    useEffect(() => {
        (async () => {
            const accessToken = (new URLSearchParams(location.search)).get("access_token");
            if (accessToken) await getJwt(accessToken);
            else localStorage.removeItem('jwt');
            window.location.href = localStorage.getItem('redirect') || "/";
        })();
    }, []);

    return null;
};

export default Callback;
